import React, { useState } from 'react';
import { Box } from '@mui/material';
import Button from 'ui/components/Button/Button';
import EditIcon from '@mui/icons-material/Edit';
import GoBackButton from 'ui/components/shared/GoBackButton';
import ViewModal from '../ViewModal';
import UserView from '../UserView/UserView';
import EditGuest from 'ui/components/users/EditGuest';
import { t } from 'i18next';

const MenteeDetailModal = ({
  user = {},
  open,
  company = {},
  onClose,
  isLoading,
  onClickPreviousMentee,
  onClickNextMentee,
  userRole,
  directionalArrowsActivated = false,
  editable = false,
  control,
  validation,
  onSave,
  onValidateGuestSelfRegistration,
}) => {
  const [contentType, setContentType] = useState('mentee');
  const styleBox = editable ? { paddingBottom: 0 } : {};

  if (isLoading) return <></>;

  return (
    <ViewModal
      open={open}
      onClose={onClose}
      customStyleBox={{
        width: '90%',
        borderRadius: '16px',
        position: 'relative',
        height: 'none',
        maxHeight: '80%',
        ...styleBox,
      }}>
      {contentType === 'mentee' && (
        <MenteeDetail
          user={user}
          isLoading={isLoading}
          company={company}
          editable={editable}
          directionalArrowsActivated={directionalArrowsActivated}
          userRole={userRole}
          validGuestButton={user.typeGuest === 'RH_Validation'}
          onClickPreviousMentee={onClickPreviousMentee}
          onClickNextMentee={onClickNextMentee}
          onClickEditProfile={() => setContentType('edit')}
          onValidateGuestSelfRegistration={onValidateGuestSelfRegistration}
        />
      )}

      {contentType === 'edit' && (
        <MenteeEditContent
          control={control}
          validation={validation}
          onClickShowProfile={() => setContentType('mentee')}
          onSave={onSave}
        />
      )}
    </ViewModal>
  );
};

const MenteeDetail = ({
  user,
  isLoading,
  company,
  directionalArrowsActivated,
  validGuestButton,
  editable,
  userRole,
  onClickPreviousMentee,
  onClickNextMentee,
  onClickEditProfile,
  onValidateGuestSelfRegistration,
}) => {
  return (
    <>
      <UserView
        userRole={userRole}
        availabilitiesEditable
        user={user}
        isLoading={isLoading}
        company={company}
        typeView="mentee"
        directionalArrowsActivated={directionalArrowsActivated}
        availabilitiesDisabled={true}
        onClickPreviousUser={onClickPreviousMentee}
        onClickNextUser={onClickNextMentee}
      />

      {editable && (
        <MenteeViewModalFooter
          showProfileEditButton={true}
          validGuestButton={validGuestButton}
          onValidateGuestSelfRegistration={onValidateGuestSelfRegistration}
          onClickEditProfile={onClickEditProfile}
        />
      )}
    </>
  );
};

const MenteeEditContent = ({ control, validation, onClickShowProfile, onSave }) => {
  return (
    <>
      <Box>
        <GoBackButton onClick={onClickShowProfile} />

        <EditGuest sxBox={{ marginTop: '20px' }} control={control} validation={validation} />
      </Box>

      <MenteeViewModalFooter showProfileSaveButton onSave={onSave} />
    </>
  );
};

const MenteeViewModalFooter = ({
  validGuestButton,
  onClickEditProfile,
  onSave,
  onValidateGuestSelfRegistration,
  showProfileEditButton = false,
  showProfileSaveButton = false,
}) => {
  return (
    <Box
      sx={{
        padding: '16px 32px',
        background: '#FFFFFFE6',
        position: 'sticky',
        bottom: '0',
        marginTop: '16px',
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
        marginLeft: '-32px',
        marginRight: '-32px',
        justifyContent: 'end',
        display: 'flex',
        zIndex: '10000',
      }}>
      {showProfileEditButton && !validGuestButton && (
        <Button variant="primary" onClick={onClickEditProfile}>
          <EditIcon sx={{ width: '20px', height: '20px', marginRight: '6px' }} />
          {t('mentee_view_edit_profile')}
        </Button>
      )}

      {showProfileSaveButton && <Button onClick={onSave}>{t('save_profile')}</Button>}

      {validGuestButton && <Button onClick={onValidateGuestSelfRegistration}>{t('validate')}</Button>}
    </Box>
  );
};

export default MenteeDetailModal;
