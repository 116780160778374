import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import styles from './TabNavigation.styles';
import { truncateString } from '../../../../../common/utils';
import { useTranslation } from 'react-i18next';
import GoBackButton from 'ui/components/shared/GoBackButton';

const TabNavigation = ({ returnTab, onChange, options, onReturn, isSubTab, customStyle }) => {
  const [optionsState, setOptionState] = useState(options);
  const [selectedTab, setSelectedTab] = useState('');
  const { t } = useTranslation();

  const handleSelectTab = (id) => {
    const tabs = options.map((item) => {
      return { ...item, selected: false };
    });

    const updatedItems = tabs.map((item) => {
      if (item.id === id) {
        setSelectedTab(item.id);
        return { ...item, selected: true };
      }
      return item;
    });

    setOptionState(updatedItems);
  };

  const isSelected = (id) => {
    const selectedItem = optionsState.find((item) => item.id === id);
    return selectedItem ? selectedItem.selected : false;
  };

  return (
    <Grid sx={styles.container} container spacing={1}>
      {returnTab && (
        <Grid item>
          <GoBackButton
            onClick={() => {
              onReturn();
            }}
            sx={[styles.tab, styles.unselectedTab]}
          />
        </Grid>
      )}
      {options &&
        options.map((item) => (
          <Grid item key={item.id}>
            <Button
              onClick={() => {
                handleSelectTab(item.id);
                onChange(item.id);
              }}
              sx={[
                customStyle ? customStyle.tab : styles.tab,
                isSubTab && styles.subTab,
                isSelected(item.id) ? styles.selectedTab : styles.unselectedTab,
              ]}
              variant="text">
              {truncateString(item.text, 25)}
            </Button>
          </Grid>
        ))}
    </Grid>
  );
};

export default TabNavigation;
