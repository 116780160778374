import { useEffect, useState } from 'react';
import { useListGuestsWithParams } from 'hooks/queries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';

import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export const useGuests = (queryParams) => {
  // React query
  const { data, isLoading, isFetched, refetch } = useListGuestsWithParams(queryParams);
  const { setStateAlert, stateAlert } = useAlert();
  const queryClient = useQueryClient();
  const [menteeIdDetailsSelected, setMenteeIdDetailsSelected] = useState(null);

  // For edit guests
  const { handleSubmit, control, watch, getValues, setValue, reset } = useForm({
    mode: 'all',
    defaultValues: {
      scopes: [],
      role: '',
      firstname: '',
      lastname: '',
      email: '',
      idNumber: '',
    },
  });

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [editingGuest, setEditingGuest] = useState(null);
  const [source, setSource] = useState('');

  const updateList = (users) =>
    users.reduce((acc, currentItem) => {
      const modifiedItem = {
        ...currentItem,
        name: currentItem.firstname + ' ' + currentItem.lastname,
        user: {
          name: currentItem.firstname + ' ' + currentItem.lastname,
          title: currentItem.title,
          picture: currentItem.picture,
        },
        scopeName: currentItem.scopeName != null ? currentItem.scopeName : '',
        type: currentItem.internalCoach ? 'INTERNAL' : 'EXTERNAL',
        createdAt: dayjs(currentItem.createdAt).fromNow(),
      };
      acc.push(modifiedItem);
      return acc;
    }, []);

  const mutationDelete = useMutation(api.Guests.delete, {
    onSuccess: async (_data, id) => {
      refetch();
      setStateAlert({ open: true, message: t('user_deleted_successfully') });
    },
    onError: (error) => {
      setStateAlert({ open: true, message: `${error.response.data.error}` });
    },
  });

  const mutationUserDelete = useMutation(api.Users.delete, {
    onSuccess: async (_data, id) => {
      refetch();
      setStateAlert({ open: true, message: t('user_deleted_successfully') });
    },
    onError: (error) => {
      setStateAlert({ open: true, message: `${error.response.data.error}` });
    },
  });

  const handleDeleteGuest = ({ id }) => {
    return mutationDelete.mutate(id);
  };

  const handleDeleteGuestBackoffice = ({ id, source }) => {
    if (source == 'User') {
      return mutationUserDelete.mutate(id);
    } else {
      return mutationDelete.mutate(id);
    }
  };

  useEffect(() => {
    if (editingGuest) {
      setValue('firstname', editingGuest.firstname);
      setValue('lastname', editingGuest.lastname);
      setValue('email', editingGuest.email);
      setValue('idNumber', editingGuest.idNumber ? editingGuest.idNumber : '');
      setValue('scopeId', [editingGuest.scopeId]);
      setValue('scopeName', editingGuest.scopeName), setSource(editingGuest.source);
    }
  }, [editingGuest]);

  const mutationEdit = useMutation(source === 'User' ? api.Users.edit : api.Guests.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-list-guests-params']);
      setStateAlert({
        open: true,
        message: source === 'User' ? 'User successfully edited' : 'Guest successfully edited',
      });
      setIsOpenEditModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });
  const onEdit = handleSubmit(async (item) => {
    mutationEdit.mutate({ id: editingGuest.id, data: { ...item, companyId: queryParams.companyId } });
  });

  const mutationValidateGuest = useMutation(api.SelfRegistration.validateSelfRegistration, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-list-guests-params']);
      setStateAlert({
        open: true,
        message: t('validation-self-registration'),
      });
      setIsOpenEditModal(false);
      setMenteeIdDetailsSelected(null);
      reset();
    },
    onError: (error) => {
      queryClient.invalidateQueries(['get-list-guests-params']);
      setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' });
      setIsOpenEditModal(false);
      reset();
    },
  });

  const onValidateGuestSelfRegistration = (guestId) => {
    mutationValidateGuest.mutate({ id: guestId });
  };

  return {
    menteeIdDetailsSelected,
    guests: data ? updateList(data.rows) : [],
    totalPages: data && data.totalPages,
    totalItems: data && data.totalItems,
    isLoading,
    isFetched,
    handleDeleteGuest,
    handleDeleteGuestBackoffice,
    reset,
    watch,
    control,
    isOpenEditModal,
    setIsOpenEditModal,
    setEditingGuest,
    onEdit,
    onValidateGuestSelfRegistration,
    companyId: queryParams.companyId,
    isEditLoading: mutationEdit.isLoading,
    setMenteeIdDetailsSelected,
  };
};
