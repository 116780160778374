import React from 'react';
import { Box, Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import { truncateString } from 'common/utils';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import styles from './CardCoach.styles';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { Tooltip } from '@mui/material';
import Button from 'ui/components/Button/Button';

const CardCoach = ({
  id,
  title,
  job,
  description,
  skills,
  isSelected,
  onSelect,
  picture,
  timeZone,
  onShowExpert,
  availabilitiesCount,
  showSelectButton = true,
  showViewProfile = true,
}) => {
  const { t } = useTranslation();

  const handleRemoveHtmlTag = (html) => {
    const regex = /(<([^>]+)>)/gi;
    const newString = html.replace(regex, ' ');
    return newString;
  };
  return (
    <Box
      onClick={() => {
        onSelect && onSelect(id);
      }}
      sx={[
        styles.container,
        isSelected && { backgroundColor: 'card.selected', border: '1px solid', borderColor: 'primary.main' },
      ]}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          justifyContent: 'space-between',
          height: '262px',
          position: 'relative',
        }}>
        {timeZone && (
          <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
            <Tooltip title={timeZone}>
              <TravelExploreIcon sx={{ color: 'primary.main' }} />
            </Tooltip>
          </Box>
        )}
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt="Remy Sharp" src={picture ? picture : null} />
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '16px' }}>
              <Typography sx={{ fontSize: '18px', lineHeight: '26px', fontWeight: 500, color: '#000000DE' }}>
                {truncateString(`${title}`, 16)}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '18.2px',
                  fontWeight: 500,
                  color: 'primary.main',
                  height: '18.2px',
                }}>
                {truncateString(`${job ? job : ''}`, 28)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ height: '8px' }} />
          {availabilitiesCount != null && (
            <Chip
              sx={{ marginBottom: '8px' }}
              size="small"
              label={t('availabilities_count', { count: availabilitiesCount })}
              color={availabilitiesCount ? 'info' : 'default'}
              variant="outlined"
            />
          )}
          <Typography sx={{ fontSize: '14px', lineHeight: '24px', fontWeight: 400, color: '#00000099' }}>
            {truncateString(`${handleRemoveHtmlTag(description)}`, 70)}
          </Typography>
          <Box sx={{ height: '8px' }} />
          <Grid container sx={{ flexGrow: 1 }} spacing={1}>
            {skills &&
              Array.isArray(skills) &&
              skills.slice(0, 4).map((objective) => (
                <Grid key={'id-' + Math.random()} item>
                  <Tooltip title={objective && objective.length > 15 ? objective : ''} placement="bottom">
                    <Chip size="small" label={truncateString(`${t(objective)}`, 15)} color="default" />
                  </Tooltip>
                </Grid>
              ))}
          </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '8px' }}>
          {onSelect && showSelectButton && (
            <Button
              sx={{ backgroundColor: isSelected ? 'white' : 'primary.main', width: '75px' }}
              variant={isSelected ? 'secondary' : 'primary'}
              onClick={(event) => {
                event.stopPropagation();
                onSelect && onSelect(id);
              }}>
              {isSelected ? 'selected' : 'Select'}
            </Button>
          )}
          {(!onSelect || !showSelectButton) && <div></div>}
          {showViewProfile && (
            <Button
              onClick={(event) => {
                event.stopPropagation();
                onShowExpert(id);
              }}
              variant="secondary">
              {t('view_profile')}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CardCoach;
