import React, { useRef, useState } from 'react';
import useStore from 'store';
import styles from './SelfRegistrationIndex.styles.js';
import { getTags } from 'common/coaches';

// Components
import CardCoach from 'ui/pages/evergreen-link/components/Cards/CardCoach/CardCoach';
import Button from 'ui/components/Button/Button';
import { Typography } from '@mui/material';
import Box from 'ui/components/system/Box';
import NavBar from 'ui/layout/NavBar/NavBar';
import ThemeView from 'ui/components/shared/theme/ThemeView';
import ViewModal from 'ui/components/shared/ViewModal';
import LoginForm from 'ui/pages/login/LoginForm';
import SelfRegistrationModal from './SelfRegistrationModal/SelfRegistrationModal.jsx';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelfRegistration } from 'hooks/usecase/useSelfRegistration';
import useLogin from 'hooks/usecase/useLogin';
import PoweredBy from 'ui/components/PoweredBy';
import { useNavigate } from 'react-router-dom';

const SelfRegistrationIndex = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openLogin, setOpenLogin] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { state, actions: selfRegistrationActions } = useSelfRegistration();
  const { state: loginState, actions } = useLogin(false);
  const coachesListContainerRef = useRef();

  const { theme, coaches, isConnected, user, selfRegistrationLink } = state;

  const { company } = useStore();
  const { expertLabel } = company;

  const onSelectMentor = () => {
    if (!isConnected) {
      setOpenLogin(true);
    } else {
      setOpenModal(true);
    }
  };

  const onShowExpert = () => {
    if (!isConnected) {
      setOpenLogin(true);
    } else {
      setOpenModal(true);
    }
  };

  const onCloseLogin = () => {
    setOpenLogin(false);
  };

  const onClickPreviousUser = () => {
    coachesListContainerRef.current.scrollBy({
      left: -314,
      behavior: 'smooth',
    });
  };

  const onClickNextUser = () => {
    coachesListContainerRef.current.scrollBy({
      left: 314,
      behavior: 'smooth',
    });
  };

  return (
    <Box sx={{ height: '100%', overflowY: 'scroll' }}>
      <NavBar company={company} isSelfRegistration isFixed />

      <Box sx={styles.container}>
        <Box sx={styles.themeContainer}>
          <ThemeView md={12} theme={theme} paddingContent="0 24px 20px 24px" />
        </Box>

        <Box sx={styles.selectingMentorContainer}>
          <Typography component="h3" sx={styles.selectingMentorTitle}>
            {t('self_registration.selecting_mentor.title')}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <Typography component="p" sx={styles.selectingMentorDesc}>
              {t('self_registration.selecting_mentor.desc')}
            </Typography>

            <Box sx={{ textWrap: 'nowrap' }}>
              <Button
                sx={{ marginRight: '10px', ...styles.directionArrow }}
                variant="secondary"
                onClick={onClickPreviousUser}>
                <KeyboardArrowLeftIcon sx={{ fontSize: '1.3rem' }} />
              </Button>
              <Button sx={styles.directionArrow} variant="secondary" onClick={onClickNextUser}>
                <KeyboardArrowRightIcon sx={{ fontSize: '1.3rem' }} />
              </Button>
            </Box>
          </Box>

          <Box sx={styles.coachesListContainer} ref={coachesListContainerRef}>
            <Box sx={styles.coachesList}>
              {coaches.map((coach) => (
                <CardCoach
                  key={coach.id}
                  id={coach.id}
                  title={coach.firstname + ' ' + coach.lastname}
                  description={coach?.descriptionExperience || ''}
                  job={coach?.title || ''}
                  skills={getTags(coach.coachingSkills, coach.languagesSpoken)}
                  timeZone={coach.timeZoneDescription}
                  availabilitiesCount={coach.availabilitiesCount}
                  onShowExpert={onShowExpert}
                  picture={coach.picture ? coach.picture : ''}
                  showViewProfile={false}
                  showSelectButton={false}
                  onSelect={onShowExpert}
                />
              ))}
            </Box>
          </Box>
        </Box>

        <Box sx={styles.poweredByContainer}>
          <PoweredBy />
        </Box>
      </Box>

      <Box sx={styles.footer}>
        <Button variant="primary" sx={styles.selectMentorButton} onClick={onSelectMentor}>
          {t('self_registration.select_mentor', { expertLabel })}
        </Button>
      </Box>

      <SelfRegistrationModal
        company={company}
        open={openModal}
        theme={theme}
        user={user}
        selfRegistrationLink={selfRegistrationLink}
        onClose={() => setOpenModal(false)}
      />

      <ViewModal
        open={openLogin}
        onClose={onCloseLogin}
        customStyleBox={{
          maxWidth: '600px',
          borderRadius: '16px',
          position: 'relative',
          height: 'none',
          maxHeight: '90%',
          padding: '20px 60px',
        }}>
        <Box sx={styles.loginLogoCompanyContainer}>
          {company?.logo && <Box component="img" src={company.logo} alt="Logo" sx={styles.loginLogoCompany} />}
        </Box>
        <LoginForm
          activeSelfRegistrationLink
          control={loginState.form.control}
          subTitle={t('self_registration.login_subtitle')}
          onSubmit={async (data) => {
            await actions.setRedirection();
            actions.submit(data);
          }}
          onSubmitMicrosoft={async () => {
            await actions.setRedirection();
            actions.onSubmitMicrosoft();
          }}
          onSubmitCompany={async () => {
            await actions.setRedirection();
            actions.onSubmitCompany();
          }}
          onClickReset={() => {
            navigate('/login#reset');
          }}
          onClickSelfRegistration={selfRegistrationActions.onClickApplicationLinkSelfRegistration}
        />

        <Box sx={styles.poweredByContainerModal}>
          <PoweredBy />
        </Box>
      </ViewModal>
    </Box>
  );
};

export default SelfRegistrationIndex;
